import { HttpClient } from '@angular/common/http';
import { Injectable, computed, inject, signal } from '@angular/core';
import {
  CreditCardPaymentData,
  CreditCardPaymentPayload,
  CreditCardTransactionFee,
  IAddCardPayload,
  IAddCardResponse,
  ICreditCard,
  ICyberSourceCardAddRequestModel,
  INewPersonaInquiryRes,
} from './credit-card.model';
import { catchError, finalize, tap } from 'rxjs';
import { SharedService } from '../../shared/services/shared/shared.service';
import { PaymentDataService } from '../../shared/services/payment-data/payment-data.service';
import { Router } from '@angular/router';
import { IApiRes, IPaymentResponseData } from '../../models/common-models';

@Injectable({
  providedIn: 'root',
})
export class CreditCardService {
  private http = inject(HttpClient);
  private sharedService = inject(SharedService);
  private paymentDataService = inject(PaymentDataService);
  private router = inject(Router);

  rootUrl = computed(() => `${this.sharedService.rootUrl()}/api/v4`);

  options = {
    headers: { auth: 'true' },
  };

  private initialState: CreditCardPaymentData = {
    kycStatus: 'none' as const,
    existingCards: [],
    otpSent: false,
    invoiceLock: false,
    memoLock: false,
    editable: true,
    transactionFeeLoading: false,
  };

  creditCardPaymentStateSignal = signal<CreditCardPaymentData>(
    this.initialState,
  );

  failedResultData: IPaymentResponseData = {
    success: false,
    data: { ref_id: 0, form_id: '', receipt_id: '', payment_type: '' },
    status: 0,
  };

  createPayment(paylaod: CreditCardPaymentPayload) {
    this.sharedService.loading.next(true);
    return this.creditCardPayment(paylaod)
      .pipe(
        finalize(() => {
          this.sharedService.loading.next(false);
        }),
      )
      .pipe(
        tap((val) => {
          const resData = {
            success: val.success,
            status: 1,
            errorMsg: val.errorMsg,
            temp: true,
            data: {
              ref_id: 0,
              form_id: '',
              receipt_id: '',
              payment_type: '',
            },
          };

          this.redirectToResult(resData);
        }),
        catchError((_err) => {
          this.redirectToResult({
            ...this.failedResultData,
            errorMsg:
              'Oops! Your transaction was unsuccessful. Please try again',
          });
          return _err;
        }),
      );
  }

  terminateSession() {
    this.creditCardPaymentStateSignal.update(() => this.initialState);
  }

  redirectToResult(val: IPaymentResponseData | undefined) {
    this.paymentDataService.paymentResponseData = val;
    this.router.navigate([`${this.router.url.split('credit-card')[0]}result`]);
  }

  /**
   *@description API function for Adds card data to Cybersource.
   * @param sendingData Payload for the API
   */
  addCardData(sendingData: ICreditCard) {
    return this.http.post<IApiRes<ICyberSourceCardAddRequestModel>>(
      `${this.rootUrl()}/payment-methods/card`,
      sendingData,
      this.options,
    );
  }

  getAcceptedOrigins() {
    return this.http.get<{ origin_name: string }[]>(
      `${this.rootUrl()}/payment-methods/card/accepted-origins`,
      {
        ...this.options,
        params: {
          via_payment_link: 1,
        },
      },
    );
  }

  creditCardPayment(payload: CreditCardPaymentPayload) {
    return this.http.post<IAddCardResponse>(
      `${this.sharedService.rootUrl()}/outside/widget/credit-card/payment`,
      payload,
      this.options,
    );
  }

  getInquiryIdNewPersona(isTryAgain?: boolean) {
    return this.http.post<IApiRes<INewPersonaInquiryRes>>(
      `${this.rootUrl()}/persona-verifications/inquiry/create${
        isTryAgain ? '?retry=1' : ''
      }`,
      {
        inquiry_type: 'kyc',
        source: 12,
        via_payment_link: 1,
      },
      this.options,
    );
  }

  personaVerification(id: string) {
    return this.http.get<{ status: string; success: boolean }>(
      `${this.rootUrl()}/persona-verifications/${id}`,
      {
        ...this.options,
        params: {
          via_payment_link: 1,
        },
      },
    );
  }

  getTransactionFee(amount: number) {
    return this.http.get<IApiRes<CreditCardTransactionFee>>(
      `${this.rootUrl()}/payment-methods/card/service-fee`,
      {
        ...this.options,
        params: {
          via_payment_link: 1,
          mode: 1,
          amount,
        },
      },
    );
  }

  uploadCardDocuements(formData: FormData) {
    return this.http.post<IApiRes<{ card: string; cardReverse: string }>>(
      `${this.rootUrl()}/payment-methods/submit-card-documents`,
      formData,
      { ...this.options },
    );
  }

  addCard(payload: IAddCardPayload) {
    return this.http.post<IAddCardResponse>(
      `${this.rootUrl()}/payment-methods/card/add-card`,
      payload,
      { ...this.options },
    );
  }
}
